import React from "react";
import "../styles/SloganText.css"; 
function SloganText() {
  return (
    <div className="mySloganText">
      <p>“Ensemble nous donnons de la valeur à vos cérémonies”</p>
    </div>
  );
}

export default SloganText;
