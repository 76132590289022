import React from 'react'
import Copyright from './Copyright'
import Contact from './Contact'

function Footer() {
  return (
    <div>
  <Contact/>
  <Copyright/>

    </div>
  )
}

export default Footer
