import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../styles/Apropos_css/Apropos.css";
import SloganText from "../SloganText";
function Apropos() {
  return (
    <>
      <Header />
      <section id="about">
        <div>
          <h2>Qui sommes nous ?</h2>
          <p>
           
              L'histoire de <strong>CEREMONIAL PÂTISSERIE </strong> est née d'une passion profonde
              pour l'art de la pâtisserie et d'une vision de créer des moments
              inoubliables à travers des créations sucrées.
        
          </p>
          <p>
            Tout a commencé dans la cuisine d'une petite maison à Bilongue, où
            notre fondatrice, <strong>Flavie NYAMSI</strong> , passait des heures à perfectionner
            l'art délicat de la pâtisserie. Enfant, elle était fascinée par la
            magie de la transformation des ingrédients simples en de véritables
            œuvres d'art. Au fil des années, elle a développé son savoir-faire,
            inspirée par les pâtissiers renommés, tout en cultivant son propre
            style unique. Chaque gâteau, chaque tarte, chaque création sucrée
            qu'elle réalisait était une ode à la passion et à la précision.
          </p>
          <p>
            C'est ainsi qu'est née l'idée de <strong>CEREMONIAL PÂTISSERIE </strong>, un lieu où
            la pâtisserie est élevée au rang de cérémonie, où chaque dessert
            raconte une histoire et où chaque bouchée est une expérience
            sensorielle. Chez <strong>CEREMONIAL PÂTISSERIE </strong>, nous croyons que les
            moments spéciaux méritent des créations qui sont tout aussi
            spéciales. Que ce soit pour un mariage, un anniversaire, ou
            simplement pour célébrer la beauté de la vie quotidienne, nous nous
            engageons à vous offrir des douceurs qui ne sont pas seulement
            délicieuses, mais aussi esthétiquement remarquables.
          </p>
          <p>
            Notre équipe de pâtissiers talentueux et passionnés travaille avec
            des ingrédients de la plus haute qualité pour créer des desserts qui
            sont non seulement beaux à regarder, mais aussi riches en saveurs.
            Nous mettons un point d'honneur à innover constamment, en fusionnant
            les techniques traditionnelles avec des touches modernes, pour vous
            offrir une expérience culinaire inoubliable.
          </p>
          <p>
            Chez <strong>CEREMONIAL PÂTISSERIE </strong>, chaque pâtisserie est conçue pour être
            le point culminant de vos célébrations, une pièce maîtresse qui
            éblouira vos invités et créera des souvenirs durables. Nous sommes
            honorés de faire partie de vos moments les plus précieux et nous
            nous engageons à perpétuer cet héritage de passion et d'excellence à
            chaque création que nous réalisons.
          </p>
          <p>
            <strong>
              Bienvenue dans l'univers de <strong>CEREMONIAL PÂTISSERIE </strong>, où chaque
              dessert est une cérémonie en soi.
            </strong>
          </p>
        </div>
      </section>

      <SloganText />
      <Footer />
    </>
  );
}

export default Apropos;
